<template>
    <div class="users">
        <v-container>
            <v-simple-table dark>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th
                            width="10%"
                            class="text-center text-uppercase font-weight-medium"
                        >User</th>
                        <th
                            width="5%"
                            class="text-center text-uppercase font-weight-medium"
                        >Role</th>
                        <th
                            width="20%"
                            class="text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase font-weight-medium"
                        >Email</th>
                        <th
                            width="10%"
                            class="text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase font-weight-medium"
                        >Date Registered</th>
                        <th
                            width="10%"
                            class="text-center text-uppercase font-weight-medium"
                        >Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr
                        v-for="(user, index) in (isSearching ? searchedData : data)"
                        :key="index"
                    >
                        <td class="text-center">
                            <v-avatar tile color="indigo">
                                <img
                                    v-if="user.photo !== null"
                                    :src="user.photo"
                                    :alt="user.username"
                                />
                                <v-icon
                                    v-else
                                    dark
                                >fa-user</v-icon>
                            </v-avatar>
                            <br>
                            {{ user.username }}
                        </td>
                        <th
                            class="text-center"
                        >{{ user.role }}</th>
                        <th
                            class="text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"
                        >{{ user.email }}</th>
                        <th
                            class="text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"
                        >{{ toDate(user.updated_at) }}</th>

                        <th class="text-center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        @click="openModal(user)"
                                        icon small
                                        v-on="on"
                                    ><v-icon small color="accent">fa-cogs</v-icon></v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        icon small
                                        @click="user.active ? block(user.id) : unblock(user.id)"
                                        v-on="on"
                                    ><v-icon small :color="user.active ? 'accent' : 'error'">fa-ban</v-icon></v-btn>
                                </template>
                                <span>{{ user.active ? 'Block User' : 'Unblock User' }}</span>
                            </v-tooltip>
                        </th>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <div v-if="loading === 'loading'">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    class="bottom"
                />
            </div>

            <f-update-user
                v-if="modal.show"
                :user="modal.user"
                @closeModal="closeModal"
            ></f-update-user>
        </v-container>
    </div>
</template>


<script>
    import Constants from '@/constants';
    import UpdateUser from '@/views/users/components/UpdateUser.vue';

    export default {

        name: 'Users',

        props: ['keywords'],

        computed: {

            data() {
                return this.$store.getters['users/data'];
            },

            loading() {
                return this.$store.getters['users/status'];
            },

            searchedData() {
                return this.$store.getters['users/searchedData'];
            }
        },

        data: () => ({
            available: true,
            bottom: false,
            modal: {
                show: false,
                user: null
            },
            isSearching: false,
            baseURL: Constants.APP_URL
        }),

        mounted() {
            this.fetch();
        },

        created() {
            if (this.available) {
                window.addEventListener('wheel', () => {
                    this.bottom = this.bottomVisible();
                });
            }
        },

        watch: {

            bottom(bottom) {
                if (bottom && this.available) {
                    this.fetch();
                }
            },

            keywords(val) {
                if (val !== '') {
                    this.search(val);
                } else {
                    this.isSearching = false;
                }
            }
        },

        methods: {

            toDate(d) {
                return new Date(d).toLocaleString();
            },

            fetch() {
                this.$store.dispatch('users/get').then(data => {
                    this.available = data;
                }).catch(() => {});
            },

            block(id) {
                this.$store.dispatch('users/block', id).then(data => {
                    this.$store.dispatch('users/single', data.id).catch(() => {});
                }).catch(() => {});
            },

            unblock(id) {
                this.$store.dispatch('users/unblock', id).then(data => {
                    this.$store.dispatch('users/single', data.id).catch(() => {});
                }).catch(() => {});
            },

            bottomVisible() {
                const { scrollTop, offsetHeight } = document.documentElement;
                const { innerHeight } = window;
                const bottomOfWindow =
                    Math.round(scrollTop) + innerHeight === offsetHeight;

                return bottomOfWindow;
            },

            openModal(user) {
                this.modal.user = user;
                this.modal.show = true;
            },

            closeModal(data) {
                if (data) {
                    this.$store.dispatch('users/update', data).then(data => {
                        this.$store.dispatch('users/single', data.id).then(() => {
                            this.modal.user = null;
                            this.modal.show = false;
                        }).catch(() => {});
                    }).catch(() => {});
                } else {
                    this.modal.user = null;
                    this.modal.show = false;
                }
            },

            search(kw) {
                this.$store.dispatch('users/search', kw).then(() => {
                    this.isSearching = true;
                    this.available = false;
                }).catch(() => {});
            }
        },

        components: {
            FUpdateUser: UpdateUser
        }
    }
</script>
