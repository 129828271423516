<template>
    <v-dialog
        value="true"
        persistent
        max-width="512px"
    >
        <v-card v-if="tempUser !== null && roles.length > 0">
            <v-card-title>
                <span class="headline">Update User</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Username*"
                                v-model="tempUser.username"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Email*"
                                v-model="tempUser.email"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                label="Role*"
                                :items="items"
                                v-model="tempUser.role"
                                required
                                outlined
                                v-on:change="changeRole"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="accent"
                    text
                    @click="closeModal(false)"
                >Close</v-btn>
                <v-btn
                    color="accent"
                    text
                    @click="closeModal(true)"
                    :loading="isSaving"
                >Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'UpdateUser',

        props: ['user'],

        data: () => ({
            dialog: true,
            tempUser: null,
            roles: [],
            items: [],
            isSaving: false
        }),

        mounted() {
            this.isSaving = false;
            this.$store.dispatch('roles/get').then(roles => {
                _.each(roles, role => {
                    this.items.push(role.name);
                });
                this.roles = roles;
            });
            this.tempUser = {
                username: this.user.username,
                email: this.user.email,
                role: this.user.role,
                role_id: this.user.role_id
            };
        },

        methods: {

            changeRole(newVal) {
                const role = _.find(this.roles, {
                    name: newVal
                });

                if (!_.isNull(role)) {
                    this.tempUser.role_id = role.id;
                }
            },

            closeModal(save) {
                if (save) {
                    this.isSaving = true;
                    const tempUser = _.cloneDeep(this.tempUser);
                    delete tempUser.role;

                    this.$emit('closeModal', {
                        params: tempUser,
                        id: this.user.id
                    });
                } else {
                    this.$emit('closeModal', false);
                }
            }
        }
    };
</script>
